import React, { Component, Fragment } from 'react';
import ProtectedComponent from './common/ProtectedComponent';
import { getMediaBP, uploadMediaBP, removeMediaBP } from '../services/mediaService';
import { getBPbarcode } from '../services/baseplateService';
import { API_BASE } from '../services/apiService';
import { Button, Form, Row, Col } from 'react-bootstrap'
import Icons from './common/Icons';
import { AuthContext } from './Login/AuthContext';

class BaseplateMedia extends Component {
  static contextType = AuthContext;
  
  constructor(props){
    super(props);
    this.state = {
      id: props.id,
      bar_code: '',
      media : {},
      file: null,
      label: ''
    }
  }

  async componentDidMount(){
    let bar_code = await getBPbarcode(this.state.id.replaceAll('0',''));
    if(bar_code){
      this.setState({bar_code})
    }
    let data = await getMediaBP(this.state.id);

    if(data){
      data.map(item => {
        const start = item.name.indexOf('-');
        const label = item.name.substring(start+1, item.name.length);
        item['label'] = label.replaceAll('_',' ').replace('.pdf','').replace('.png','').replace('.jpeg','');
      })
      this.setState({media:data}); 
    }


  }
  
  render(){
    let {bar_code, media} = this.state;
    const { user } = this.context;  

    return (
      <Fragment>
        <ProtectedComponent>
          <h1>Baseplate {bar_code}</h1>
          <Row>
            <Col>
              <Form.Group controlId="mediaLabel" className="mb-3">
                <Form.Control 
                  type="text"
                  placeholder='Image label'
                  onChange={(e)=>{
                    this.setState({label: e.target.value});
                  }}
                />
              </Form.Group>
            </Col> 
            <Col>
              <Form.Group controlId="mediaFile" className="mb-3">
                <Form.Control 
                  type="file"
                  onChange={(e)=>{
                    this.setState({file: e.target.files[0]});
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Button variant='info' 
                disabled={this.state.label===null || this.state.label==='' || this.state.file===null}
                onClick={async()=>{
                  let {id, label, file} = this.state;
                  let mimeType = file.type;
                  let file_type = '';
                  if (mimeType === 'image/jpeg' || mimeType === 'image/png' || mimeType === 'application/pdf') {
                    if(mimeType === 'image/jpeg') file_type = 'jpeg';
                    if(mimeType === 'image/png') file_type = 'png';
                    if(mimeType === 'application/pdf') file_type = 'pdf';
                    const newdata = await uploadMediaBP(id, file, label, file_type);
                    window.location.reload();      
                  } else {
                    alert (new Error('Invalid file type. Only JPG, PNG, and PDF files are allowed.'));
                  }
                }}
                >
                Add media {Icons.files}
              </Button>
            </Col>
          </Row>
          <ul>
          {media.length>0 && media.map((file, index) => (
            <li key={index}>
              <a href={`${API_BASE}${file.url}`} target="_blank" rel="noopener noreferrer">
                {file.label}
              </a>
              {
                user.role==='admin' && 
                <Button variant='light' size='sm' onClick={async()=>{await removeMediaBP(this.state.id, file.name); window.location.reload(); }}>{Icons.remove}</Button>
              }
            </li>
          ))}
          </ul>
        </ProtectedComponent>
      </Fragment>
    );
  }
}

export default BaseplateMedia;