import React, { Fragment } from 'react';
import { Link } from "react-router-dom";

const Home = () => {

  return (
    <Fragment >
      <h1>Welcome to HGCal ETP database</h1>

      <h2>How to login?</h2>

      <ul>
        <li><Link to="/login">Login</Link> or <Link to="/signup">Sign up</Link> using your ETP username. For users who do not have an ETP username, use your first name initial and full last name.</li>
        <li>Upon account creation, the new user will need to ask the <a href="https://mattermost.etp.kit.edu/ietp/channels/hgcal-database" target='_blank' rel="noopener noreferrer">admins</a> to grant permissions to access the database.</li>
        <li>Admins can change user permissions <Link>here</Link>.</li>
        <li>You found a bug? Reports <a href="https://mattermost.etp.kit.edu/ietp/channels/hgcal-database" target='_blank' rel="noopener noreferrer">here</a>.</li>
      </ul>

      <h2>Useful links</h2>
      <ul>
        <li>ETP HGCal CMS Central DB <a href='https://gitlab.etp.kit.edu/hgcal-etp-team/baseplate/-/wikis/CMS-Central-DB' target='_blank' rel="noopener noreferrer">here</a>.</li>
        <li>EDMS definitinions <a href='https://edms.cern.ch/ui/#!master/navigator/project?P:100567336:100567336:subDocs' target='_blank' rel="noopener noreferrer">here</a>.</li>
      </ul>
    </Fragment>
  );
}


export default Home;
