import api, {API_BASE} from './apiService'
import axios from 'axios';
import { getCurrentDateDDMMYY } from './commonService';

export async function getAllBP() {
  const { data } = await api.get(`${API_BASE}/list-bp`, { params :{} });
  return data;
}

export async function getAllLamBP() {
  const { data } = await api.get(`${API_BASE}/list-lam-bp`, { params :{} });
  return data;
}

export async function deleteBP(id) {
  const response = await axios
  .delete(`${API_BASE}/delete-bp/${id}`, {data:{id}})
  .catch((error) => console.log("Error: ", error));
  if (response && response.data) {
    return response.data;
  }
}
  
export async function addBP(form, username) {
  let addform = {};
  addform['preqc_user']=username;
  addform['preqc_date']=new Date();
  let heightbare = [];
  let heightlam = [];

  Object.keys(form).forEach((item) => {
    if(form[item]!=null){
      addform[item]=form[item];  
      if(item.startsWith('height_bare')) {
        heightbare.push(parseFloat(form[item]))
      }
      if(item.startsWith('height_lam')) {
        heightlam.push(parseFloat(form[item]))
      }
    }
  })

  //calculate DIST_HOLE_SLOT if min max exist
  if(form['min_hole_dist']!==null && form['max_hole_dist']!==null){
    addform['dist_hole_slot'] = (parseFloat(form['min_hole_dist']) + parseFloat(form['max_hole_dist'])) / 2;
  }
  
  //calculate FLATNESS_BARE if height_bare exist
  if(heightbare.length>0){
    let flatness_bare = Math.max(...heightbare)-Math.min(...heightbare);
    addform['flatness_bare'] = flatness_bare.toFixed(3);
  }

  //calculate FLATNESS_LAM if height_lam exist
  if(heightlam.length>0){
    let flatness_lam = Math.max(...heightlam)-Math.min(...heightlam);
    addform['flatness_lam'] = flatness_lam.toFixed(3);
  }
  const { data } = await api.post(`${API_BASE}/add-bp`, {form:addform});
  return data;
}

export async function getBP(id) {
  const { data } = await api.get(`${API_BASE}/get-bp/${id}`, {data:{id}});
  return data;
}


export async function updateBP(id, form, username, flatness_bare, flatness_lam, dist_hole_slot) {
  let editform = {};
  let isLam = false;

  Object.keys(form).forEach((item) => {
    if(form[item]!=null){
      if(item.includes('lam')) isLam = true;
      editform[item]=form[item]==='' ? null : form[item];
    }
  })

  if(isLam){
    editform['lam_user']=username;
    editform['lam_date']=new Date();
  }

  //calculate DIST_HOLE_SLOT if min max exist
  // if(form['min_hole_dist']!==null && form['max_hole_dist']!==null){
  //   editform['dist_hole_slot'] = (parseFloat(form['min_hole_dist']) + parseFloat(form['max_hole_dist'])) / 2;
  // }


  // //calculate FLATNESS_BARE if height_bare exist
  // if(heightbare.length>0){
  //   let flatness_bare = Math.max(...heightbare)-Math.min(...heightbare);
  //   editform['flatness_bare'] = flatness_bare.toFixed(3);
  // }

  // //calculate FLATNESS_LAM if height_lam exist
  // if(heightlam.length>0){
  //   let flatness_lam = Math.max(...heightlam)-Math.min(...heightlam);
  //   editform['flatness_lam'] = flatness_lam.toFixed(3);
  // }

  editform['dist_hole_slot'] = dist_hole_slot;
  editform['flatness_bare'] = flatness_bare;
  editform['flatness_lam'] = flatness_lam;

  const { data } = await api.post(`${API_BASE}/update-bp`, {id:id, form:editform});
  return data;
}

export async function getBPbarcode(id) {
  const { data } = await api.get(`${API_BASE}/get-bp-barcode/${id}`, {data:{id}});
  return data;
}

export async function createXMLnewparts(selected) {
  let list = selected.join(',')
  const { data } = await api.get(`${API_BASE}/export-xml-newparts/${list}`, {list:list});
  if(data){
    // Create a Blob from the text content
    const blob = new Blob([data], { type: "text/plain" });
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    let today = getCurrentDateDDMMYY();

    a.download = `KIT_newparts_${today}.xml`;

    // Trigger the click event on the <a> element
    a.click();

    // Cleanup the URL object
    window.URL.revokeObjectURL(url);
  }
  return data;
}

export async function createXMLqcgrade(selected) {
  let list = selected.join(',')
  const { data } = await api.get(`${API_BASE}/export-xml-qcgrade/${list}`, {list:list});
  if(data){
    // Create a Blob from the text content
    const blob = new Blob([data], { type: "text/plain" });
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    let today = getCurrentDateDDMMYY();

    a.download = `KIT_qcgrade_${today}.xml`;

    // Trigger the click event on the <a> element
    a.click();

    // Cleanup the URL object
    window.URL.revokeObjectURL(url);
  }
  return data;
}

export async function getAllKOPs() {
  const { data } = await api.get(`${API_BASE}/list-kop`, { params :{} });
  return data;
}