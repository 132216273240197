import api, {API_BASE, MEDIA_BASE} from './apiService'
import axios from 'axios';

import qs from 'qs';

export async function uploadMediaDiagrams(file, label, file_type) {
  const formData = new FormData();
  formData.append("file", file);

  // Create query string using qs.stringify
  const queryString = qs.stringify({
    subdirectory: "diagrams",
    label_diagram: label.replaceAll(' ','_'),
    file_type: file_type
  });
  const { data } = await api.post(`${API_BASE}/upload?${queryString}`, formData);
  return data;
}


export async function getMediaDiagrams() {
  // Create query string using qs.stringify
  const queryString = qs.stringify({
    subdirectory: "diagrams",
  });
  const { data } = await api.get(`${API_BASE}/media?${queryString}`);
  return data.files;
}


export async function removeMediaDiagrams(filename) {
  // Create query string using qs.stringify
  const queryString = qs.stringify({
    subdirectory: "diagrams",
    filename
  });
  const response = await axios
  .delete(`${API_BASE}/delete-media/?${queryString}`)
  .catch((error) => console.log("Error: ", error));
  if (response && response.data) {
    return response.data;
  }
}

export async function getMediaBP(id) {
  // Create query string using qs.stringify
  const queryString = qs.stringify({
    subdirectory: "baseplates/"+id,
  });
  const { data } = await api.get(`${API_BASE}/media?${queryString}`);
  return data.files;
}

export async function uploadMediaBP(id, file, label, file_type) {
  const formData = new FormData();
  formData.append("file", file);

  // Create query string using qs.stringify
  const queryString = qs.stringify({
    subdirectory: "baseplates/"+id,
    label_diagram: label.replaceAll(' ','_'),
    file_type: file_type
  });
  const { data } = await api.post(`${API_BASE}/upload?${queryString}`, formData);
  return data;
}


export async function removeMediaBP(id, filename) {
  // Create query string using qs.stringify
  const queryString = qs.stringify({
    subdirectory: "baseplates/"+id,
    filename
  });
  const response = await axios
  .delete(`${API_BASE}/delete-media/?${queryString}`)
  .catch((error) => console.log("Error: ", error));
  if (response && response.data) {
    return response.data;
  }
}