import Icons from "./Icons";
import { Button, OverlayTrigger, Popover, ListGroup, Col, Row } from 'react-bootstrap'


export const NiceBoolean = ({ value }) => {
	return (
		<div style={{textAlign:'center'}}>{value===null ? Icons.empty : value===true? Icons.ok : Icons.wrong}</div>
	);
};



export const GradingTooltip = ({position}) => {
  const popover = (
    <Popover id="popover-basic" style={{minWidth:'fit-content', maxHeight:'500px', overflowY: 'scroll'}}>
      <Popover.Header as="h3">Grading info</Popover.Header>
      <Popover.Body>
        <h6>Distance from hole to slot: nominal 74.95</h6>
        <ListGroup>
          <ListGroup.Item variant="success">Green: within +/- 0.1 of nominal</ListGroup.Item>
          <ListGroup.Item variant="warning">Orange: within +/0.2 of nominal</ListGroup.Item>
          <ListGroup.Item variant="danger">Red: beyond +/-0.2 of nominal</ListGroup.Item>
        </ListGroup>
        <br />
        <h6>Length of slot: nominal 3.55</h6>
        <ListGroup>
          <ListGroup.Item variant="success">Green: within +/- 0.1 of nominal</ListGroup.Item>
          <ListGroup.Item variant="warning">Orange: within +/0.2 of nominal</ListGroup.Item>
          <ListGroup.Item variant="danger">Red: beyond +/-0.2 of nominal</ListGroup.Item>
        </ListGroup>
        <br />
        <h6>Thickness</h6>
        <Row>
          <Col>
            <ListGroup>
              <ListGroup.Item variant="light">CuW: nominal 1.400</ListGroup.Item>
              <ListGroup.Item variant="success">Green: within +/- 0.1 of nominal</ListGroup.Item>
              <ListGroup.Item variant="warning">Orange: within +/0.2 of nominal</ListGroup.Item>
              <ListGroup.Item variant="danger">Red: beyond +/-0.2 of nominal</ListGroup.Item>
            </ListGroup>
          </Col>
          <Col>
            <ListGroup>
              <ListGroup.Item variant="light">Ti: nominal 1.100</ListGroup.Item>
              <ListGroup.Item variant="success">Green: within +/- 0.1 of nominal</ListGroup.Item>
              <ListGroup.Item variant="warning">Orange: within +/0.2 of nominal</ListGroup.Item>
              <ListGroup.Item variant="danger">Red: beyond +/-0.2 of nominal</ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
        <br />
        <h6>Flatness</h6>
        <Row>
          <Col>
            <ListGroup>
              <ListGroup.Item variant="light">CuW</ListGroup.Item>
              <ListGroup.Item variant="success"> {'Green (MAX-MIN) < 0.1'} </ListGroup.Item>
              <ListGroup.Item variant="warning"> {'Orange (MAX-MIN) < 0.2'} </ListGroup.Item>
              <ListGroup.Item variant="danger"> {'Red (MAX-MIN) > 0.2'}</ListGroup.Item>
            </ListGroup>
          </Col>
          <Col>
            <ListGroup>
              <ListGroup.Item variant="light">Ti</ListGroup.Item>
              <ListGroup.Item variant="success"> {'Green (MAX-MIN) < 0.1'} </ListGroup.Item>
              <ListGroup.Item variant="warning"> {'Orange (MAX-MIN) < 0.2'} </ListGroup.Item>
              <ListGroup.Item variant="danger"> {'Red (MAX-MIN) > 0.2'}</ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
        <br />
        <h6>Width edge: nominal 166.94</h6>
        <ListGroup>
          <ListGroup.Item variant="info"> Deep green, within +0/-0.1 from nominal </ListGroup.Item>
          <ListGroup.Item variant="success"> Light green, within +0.1/-0 from nominal </ListGroup.Item>
          <ListGroup.Item variant="warning">Orange within +0.2/-0.12 of nominal </ListGroup.Item>
          <ListGroup.Item variant="danger"> Red: beyond +0.2/-0.12 of nonimal</ListGroup.Item>
        </ListGroup>
        <br />
        <h6>Radius: nominal 81.95</h6>
        <ListGroup>
          <ListGroup.Item variant="success"> Green: within +/- 0.05 of nominal </ListGroup.Item>
          <ListGroup.Item variant="warning">Orange: within +0.1/-0.06 of nominal </ListGroup.Item>
          <ListGroup.Item variant="danger"> Red: beyond +0.1/-0.06 of nominal</ListGroup.Item>
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement={position} overlay={popover}>
      <Button variant="light">{Icons.info}</Button>
    </OverlayTrigger>
  );
}
