import React, { Component, Fragment } from 'react';
import TableWithPagination, {SelectColumnFilter, SelectColumnFilterBoolean} from './common/TableWithPagination';
import { Button, ListGroup, ListGroupItem, Row, ButtonGroup, Col } from 'react-bootstrap'
import { getAllBP, deleteBP} from '../services/baseplateService';
import Icons from './common/Icons';
import BaseplateEdit from './BaseplateEdit';
import ProtectedComponent from './common/ProtectedComponent';
import { AuthContext } from './Login/AuthContext';
import { Link } from 'react-router-dom';
import { NiceBoolean } from './common/Common';
import BaseplateAdd from './BaseplateAdd';
import { typeBaseplate, calcDistHoleSlot, calcLengthSlot, calcGrade, calcThickness, calcFlatness, calcWidthEdge , calcRadius} from '../services/commonService';

class BaseplateList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    this.state = {
      loading:true,
      data : [],
      baseplates: [],
      showAdd: false,
      showEdit: false,
      showUnexported: false,
      selected: [],
      errors:{},
    }
  }

  columns = [
    {
      Header:'Type',
      accessor:'',
      Cell: (props) => {
        let barcode = props.row.original.bar_code;
        return(
          <Fragment>
            {typeBaseplate(barcode)}
          </Fragment>
        );
      }
    },
    {
      Header:'Barcode',
      minWidth: 270,
      accessor: 'bar_code',
      filter: 'fuzzyText',
      Cell: (props) => {
        let id = props.row.original.id;
        return(
          <Fragment>
            {props.cell.value}
            {props.row.original.edit && (
              <BaseplateEdit 
                isOpen={props.row.original.edit}
                id={id}
                username={this.context.user.username}
              />
            )}
            <ButtonGroup aria-label="Bp actions">
              <Link to={`/baseplate-media/${id.toString().padStart(5, '0')}`} disabled>
                <Button
                  variant='light' 
                  size='sm'  
                >
                  {Icons.folder}
                </Button>
              </Link>
              <Button 
                variant='light' 
                size='sm'
                onClick={()=>{
                  let mydata = this.state.data;
                  mydata.forEach(item => {
                    if (item['id']===id){
                      item['edit']=true;
                    }
                  })
                  this.setState({data:mydata}); 
                }}
                >
                {Icons.edit}
              </Button>
              {this.context.user?.role==='admin' && 
                <Button 
                  variant='light' 
                  size='sm'
                  onClick= {async () => {
                    let deleted_id = await deleteBP(id); 
                    let new_data =  this.state.data.filter(item => item.id!==deleted_id.id)
                    this.setState({data:new_data}); 
                  }}
                >
                  {Icons.remove}
                </Button>
              }

            </ButtonGroup>
          </Fragment>
        );
      }
    },
    {
      Header:'Workshop serial',
      accessor: 'workshop_serial',
      Cell: (props) => {
        return (props.cell.value!==null && props.cell.value!=='')&& String(props.cell.value).padStart(6, '0');
      }
    },
    {
      Header:'Weight bare',
      accessor: 'weight_bare'
    },
    {
      Header:'Weight lamination',
      accessor: 'weight_lam'
    },
    {
      Header:'Thickness',
      accessor: 'thickness',
      getCellStyle: (value, row) => ({
        backgroundColor: calcThickness(value, row),
      }),
    },
    {
      Header:'Height bare',
      accessor: '',
      Cell: (props) => {
        let original_row = props.cell.row.original;
        const listItems = [];
        for (let i = 0; i <= 9; i++) {
          listItems.push(<ListGroupItem key={`height_bare${i}`}>{original_row[`height_bare${i}`]}</ListGroupItem>);
        }
        return(
          <Fragment>
            <ListGroup>              
              {listItems}
            </ListGroup>
          </Fragment>
        );
      }
    },   
    {
      Header:'Flatness bare',
      accessor: 'flatness_bare',
      getCellStyle: (value, row) => ({
        backgroundColor: calcFlatness(value,row),
      }),
    },
    {
      Header:'Height lam',
      accessor: '',
      Cell: (props) => {
        let original_row = props.cell.row.original;
        const listItems = [];
        for (let i = 0; i <= 9; i++) {
          listItems.push(<ListGroupItem key={`height_lam${i}`}>{original_row[`height_lam${i}`]}</ListGroupItem>);
        }
        return(
          <Fragment>
            <ListGroup>              
              {listItems}
            </ListGroup>
          </Fragment>
        );
      }
    },   
    {
      Header:'Flatness lam',
      accessor: 'flatness_lam',
      getCellStyle: (value, row) => ({
        backgroundColor: calcFlatness(value,row),
      }),
    },
    {
      Header:'Flatness grade',
      accessor: 'flatness_grade',
      Filter: SelectColumnFilter,
      getCellStyle: (value) => ({
        backgroundColor: calcGrade(value),
        textAlign: 'center'
      }),
    },
    {
      Header:'Width edge',
      accessor: '',
      Cell: (props) => {
        let original_row = props.cell.row.original;
        const listItems = [];
        for (let i = 0; i <= 2; i++) {
          listItems.push(<ListGroupItem key={`width_edge${i}`} style={{backgroundColor : calcWidthEdge(original_row[`width_edge${i}`])}} >{original_row[`width_edge${i}`]}</ListGroupItem>);
        }
        return(
          <Fragment>
            <ListGroup>              
              {listItems}
            </ListGroup>
          </Fragment>
        );
      }
    },
    {
      Header:'Width corner',
      accessor: '',
      Cell: (props) => {
        let original_row = props.cell.row.original;
        const listItems = [];
        for (let i = 0; i <= 2; i++) {
          listItems.push(<ListGroupItem key={`width_corner${i}`}>{original_row[`width_corner${i}`]}</ListGroupItem>);
        }
        return(
          <Fragment>
            <ListGroup>              
              {listItems}
            </ListGroup>
          </Fragment>
        );
      }
    },
    {
      Header:'Radius',
      accessor: '',
      Cell: (props) => {
        let original_row = props.cell.row.original;
        const listItems = [];
        for (let i = 0; i <= 5; i++) {
          listItems.push(<ListGroupItem key={`radius${i}`} style={{backgroundColor : calcRadius(original_row[`radius${i}`])}} >{original_row[`radius${i}`]}</ListGroupItem>);
        }
        return(
          <Fragment>
            <ListGroup>              
              {listItems}
            </ListGroup>
          </Fragment>
        );
      }
    },
    {
      Header:'Min dist from hole to slot',
      accessor: 'min_hole_dist'
    },
    {
      Header:'Max dist from hole to slot',
      accessor: 'max_hole_dist'
    },
    {
      Header:'Distance from hole to slot',
      accessor: 'dist_hole_slot',
      // customStyle: { backgroundColor: 'blue' },
      getCellStyle: (value) => ({
        backgroundColor: calcDistHoleSlot(value),
      }),
    },
    {
      Header:'Diameter hole passed',
      accessor: 'diameter_hole_passed',
      Filter: SelectColumnFilterBoolean,
      Cell: (props) => {
        return(
          <NiceBoolean value={props.cell.value} />
        );
      }
    },
    {
      Header:'Length of slot',
      accessor: 'length_slot',
      getCellStyle: (value) => ({
        backgroundColor: calcLengthSlot(value),
      }),
    },
    {
      Header:'Width of slot passed',
      accessor: 'width_slot_passed',
      Cell: (props) => {
        return(
          <NiceBoolean value={props.cell.value} />
        );
      }
    },
    {
      Header:'Notch size passed',
      accessor: 'notch_size_passed',
      Cell: (props) => {
        return(
          <NiceBoolean value={props.cell.value} />
        );
      }
    },
    {
      Header:'Tab width',
      accessor: '',
      Cell: (props) => {
        let original_row = props.cell.row.original;
        const listItems = [];
        for (let i = 0; i <= 2; i++) {
          listItems.push(<ListGroupItem key={`tab_width${i}`}>{original_row[`tab_width${i}`]}</ListGroupItem>);
        }
        return(
          <Fragment>
            <ListGroup>              
              {listItems}
            </ListGroup>
          </Fragment>
        );
      }
    },    
    {
      Header:'Tab height',
      accessor: '',
      Cell: (props) => {
        let original_row = props.cell.row.original;
        const listItems = [];
        for (let i = 0; i <= 2; i++) {
          listItems.push(<ListGroupItem key={`tab_height${i}`}>{original_row[`tab_height${i}`]}</ListGroupItem>);
        }
        return(
          <Fragment>
            <ListGroup>              
              {listItems}
            </ListGroup>
          </Fragment>
        );
      }
    },  
    {
      Header:'Kapton align passed',
      accessor: 'kapton_align_passed',
      Cell: (props) => {
        return(
          <NiceBoolean value={props.cell.value} />
        );
      }
    },
    {
      Header:'Test date',
      accessor: 'test_date'
    },
    {
      Header:'Tolerance grade',
      accessor: 'tolerance_grade',
      Filter: SelectColumnFilter,
      getCellStyle: (value) => ({
        backgroundColor: calcGrade(value),
        textAlign: 'center'
      }),
    },
    {
      Header:'Comment',
      accessor: 'comment'
    },
    {
      Header:'Shipment date',
      accessor: 'shipment_date',
      Cell: (props) => {
        const date = props.cell.value!=null ? new Date(Date.parse(props.cell.value)) : '';
        return(
          <Fragment>
            {date!=='' && date.toLocaleString("en-US")}
          </Fragment>
        );
      }  
    },
    {
      Header:'Pre QC by',
      accessor: '',
      Cell: (props) => {
        const preqc_date = props.row.original.preqc_date;
        const preqc_user = props.row.original.preqc_user;
        const date = preqc_date!==null ? new Date(Date.parse(preqc_date)) : null;
        return(
          <Fragment>
            {preqc_user}
            <br />
            {date!==null && date.toLocaleString("en-US")}
          </Fragment>
        );
      }      
    },
    {
      Header:'Lamination by',
      accessor: '',
      Cell: (props) => {
        const lam_date = props.row.original.lam_date;
        const lam_user = props.row.original.lam_user;
        const date = lam_date!==null ? new Date(Date.parse(lam_date)) : null;
        return(
          <Fragment>
            {lam_user}
            <br />
            {date!==null && date.toLocaleString("en-US")}
          </Fragment>
        );
      }  
    },
  ]
  
  async componentDidMount(){
    // this.state.data = await getAllBP();
    let mydata = await getAllBP();
    mydata.forEach(item => {
      item['edit'] = false;      
    })
    this.setState({data:mydata, baseplates:mydata});
  }

  showUnexportedData(){ 
    this.state.loading = true;
    this.state.showUnexported = !this.state.showUnexported;

    if(this.state.showUnexported) {
      let new_data = this.state.data.filter((item) => !item.is_db_central);
      this.state.data = new_data;
    }else{
      this.state.data = this.state.baseplates;
    }
    // This is necessary to force React-table to re-render :(
    this.columns = [...this.columns];
    this.state.loading = false;
    this.setState(this.state);
  }

  render(){
    const { user } = this.context;  
    return (
      <Fragment>
        <ProtectedComponent>
          {
            user?.role==='public' && 
            <h4>This page is not public. Please contact the admin to approve access.</h4>
          }
          {
            user?.role!=='public' && 
            <Fragment>
              <Row style={{textAlign: 'right'}}>
                <Link to="/baseplate-lamination-list">
                  Lamination view
                </Link>
              </Row>
              <h1>Baseplates</h1>
              <br />
              <Row>
                <Col sm={3}>
                  <Button 
                    variant='dark'
                    onClick={() => {
                      this.setState({showAdd:true});          
                    }}
                  >
                    Add baseplate <span>&#43;</span>
                  </Button>
                </Col>
                {/* <Col>
                  <Form.Check
                    className="float-right m-2"
                    onChange={()=> this.showUnexportedData()}
                    //inline
                    label={`Show only baseplates that are not in central DB`}
                    type="checkbox"
                    id={`show_unexported`}
                  />
                </Col> */}
              </Row>
              <br /><br />
              {this.state.showAdd && <BaseplateAdd isOpen={this.state.showAdd} user={user} />}
     
              <TableWithPagination 
                columns={this.columns}
                data={this.state.data}
                defaultPageSize={50}
                isSelect={true}
                callbackselected={(incoming_data)=>{
                  this.setState({selected: incoming_data});
                }}
              />
            </Fragment>
          }
        </ProtectedComponent>
      </Fragment>
    );
  }
}

export default BaseplateList;