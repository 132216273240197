import React, { Component, Fragment } from 'react';
import ProtectedComponent from './common/ProtectedComponent';
import { uploadMediaDiagrams, getMediaDiagrams, removeMediaDiagrams } from '../services/mediaService';
import { Button, Form, Row, Col } from 'react-bootstrap'
import Icons from './common/Icons';
import { API_BASE } from '../services/apiService';
import { AuthContext } from './Login/AuthContext';

class Diagrams extends Component {
  static contextType = AuthContext;
  
  constructor(props){
    super(props);
    this.state = {
      media : [],
      file: null,
      label: '',
    }
  }

  async componentDidMount(){
    let media = await getMediaDiagrams();
    media.map(item => {
      const start = item.name.indexOf('-');
      const label = item.name.substring(start+1, item.name.length);
      item['label'] = label.replaceAll('_',' ').replace('.pdf','').replace('.png','').replace('.jpeg','');
    })
    this.setState({media})  
  }
  
  render(){
    let {media} = this.state;
    const { user } = this.context;  

    return (
      <Fragment>
        <ProtectedComponent>
          <h1>Diagrams</h1>
          <Row>
            <Col>
              <Form.Group controlId="mediaLabel" className="mb-3">
                <Form.Control 
                  type="text"
                  placeholder='Diagram label'
                  onChange={(e)=>{
                    this.setState({label: e.target.value});
                  }}
                />
              </Form.Group>
            </Col>         
            <Col>
              <Form.Group controlId="mediaFile" className="mb-3">
                <Form.Control 
                  type="file"
                  onChange={(e)=>{
                    this.setState({file: e.target.files[0]});
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Button variant='primary' 
                disabled={this.state.label===null || this.state.label==='' || this.state.file===null}
                onClick={async()=>{
                  let label = this.state.label;
                  let file = this.state.file;
                  let mimeType = file.type;
                  let file_type = '';
                  if (mimeType === 'image/jpeg' || mimeType === 'image/png' || mimeType === 'application/pdf') {
                    if(mimeType === 'image/jpeg') file_type = 'jpeg';
                    if(mimeType === 'image/png') file_type = 'png';
                    if(mimeType === 'application/pdf') file_type = 'pdf';
                    const newdata = await uploadMediaDiagrams(file, label, file_type);
                    window.location.reload();      

                  } else {
                    alert (new Error('Invalid file type. Only JPG, PNG, and PDF files are allowed.'));
                  }
                }}>
                Add media {Icons.files}
              </Button>
            </Col>
          </Row>
          <br />
          <ul>
            {media.length>0 && media.map((file, index) => (
              <li key={index}>
                <a href={`${API_BASE}${file.url}`} target="_blank" rel="noopener noreferrer">
                  {file.label} 
                </a>
                {
                  user.role==='admin' && 
                  <Button variant='light' size='sm' onClick={async()=>{await removeMediaDiagrams(file.name); window.location.reload(); }}>{Icons.remove}</Button>
                }
              </li>
            ))}
          </ul> 

        
        </ProtectedComponent>
      </Fragment>
    );
  }

}

export default Diagrams;