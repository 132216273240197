import React, { Component, Fragment } from 'react';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap'
import { getBP, updateBP } from '../services/baseplateService';
import Icons from './common/Icons';
import { isValidAlphanumeric, isValidDecimal, getHeight, getFlatnessGrade, isPositiveInteger } from '../services/commonService';
import { GradingTooltip } from './common/Common';

class BaseplateEdit extends Component {
  constructor(props){
    super(props);
    this.state = {
      username : props.username,
      loading : true,
      isOpen: props.isOpen,
      id: props.id,
      flatness_grade_suggestion_cuw: null,
      flatness_grade_suggestion_ti: null,
      data: {},
      formEdit: {
        bar_code : null,
        workshop_serial : null,
        weight_bare: null,
        weight_lam: null,
        thickness: null,
        flatness_grade: null,
        diameter_hole_passed: null,
        length_of_slot: null,
        width_lot_passed: null,
        notch_size_passed: null,
        kapton_align_passed: null,
        test_date: null,
        tolerance_grade: null,
        comment: null,
        height_bare0 : null,
        height_bare1 : null,
        height_bare2 : null,
        height_bare3 : null,
        height_bare4 : null,
        height_bare5 : null,
        height_bare6 : null,
        height_bare7 : null,
        height_bare8 : null,
        height_bare9 : null,
        height_lam0 : null,
        height_lam1 : null,
        height_lam2 : null,
        height_lam3 : null,
        height_lam4 : null,
        height_lam5 : null,
        height_lam6 : null,
        height_lam7 : null,
        height_lam8 : null,
        height_lam9 : null,
        radius0 : null,
        radius1 : null,
        radius2 : null,
        radius3 : null,
        radius4 : null,
        radius5 : null,
        width_edge0 : null,
        width_edge1 : null,
        width_edge2 : null,
        width_corner0 : null,
        width_corner1 : null,
        width_corner2 : null,
        tab_width0 : null,
        tab_width1 : null,
        tab_width2 : null,
        tab_height0 : null,
        tab_height1 : null,
        tab_height2 : null,
        min_hole_dist: null,
        max_hole_dist: null,
      },
      errors:{}
    }

  }

  async componentDidMount(){
    if(this.state.isOpen){
      let data = await getBP(this.state.id);
      if(data){
        this.computeFlatnessGrade(data[0])
        this.setState({data: data[0], loading:false }); 
      }
    }
  }


  changeFormEdit(e, field){
    let {formEdit, data} = this.state;
    formEdit[field] =  e.target.value;
    data[field] = e.target.value;
    this.setState({formEdit, data }); 
  }

  textFormEdit(field, label){ 
    let {data, errors, formEdit} = this.state; 
    return(
      <Fragment key={`${field}field`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Control 
              type="text" 
              value={data[field] ? data[field] : ''} 
              onChange={(e)=>{
                //validate value
                if(isValidAlphanumeric(e.target.value) ){
                  formEdit[field] = e.target.value;
                  data[field] = e.target.value;
                  errors[field] = null;
                  this.setState({ formEdit, data, errors });
                }else{
                  errors[field] = "only alphanumerical characters";
                  this.setState({errors});
                }
              }}
              isInvalid={!!errors[field]}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errors[field]}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Fragment>
    )
  }


  decimalFormEdit(field, label){ 
    let {data, errors, formEdit} = this.state; 
    return(
      <Fragment key={`${field}field`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Control 
              type="text" 
              value={data[field] ? data[field] : ''} 
              onChange={(e)=>{
                //validate value
                if(isValidDecimal(e.target.value)){
                  formEdit[field] = e.target.value;
                  data[field] = e.target.value;
                  errors[field] = null;
                  this.setState({ formEdit, data, errors });
                }else{
                  errors[field] = "only decimal";
                  this.setState({errors});
                }
              }}
              isInvalid={!!errors[field]}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errors[field]}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Fragment>
    )
  }


  integerFormEdit(field, label){ 
    let {data, errors, formEdit} = this.state; 
    return(
      <Fragment key={`${field}field`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Control 
              type="text" 
              value={data[field] ? data[field] : ''} 
              onChange={(e)=>{
                //validate value
                if(isPositiveInteger(e.target.value)){
                  formEdit[field] = e.target.value;
                  data[field] = e.target.value;
                  errors[field] = null;
                  this.setState({ formEdit, data, errors });
                }else{
                  errors[field] = "only positive integer";
                  this.setState({errors});
                }
              }}
              isInvalid={!!errors[field]}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errors[field]}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Fragment>
    )
  }

  fieldFormRead(field, label, value){
    return(
      <Fragment key={`${field}field`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Control 
              type="text" 
              value={value} 
              readOnly
              />
          </Col>
        </Row>
      </Fragment>
    )
  }


  arrayFormEdit(field, size, label){
    let {formEdit, data, errors} = this.state;

    const listItems = [];
    for (let i = 0; i < size; i++) {
      listItems.push(
        <Col key={`col_${field}${i}`} sm={1}>
          <Form.Control 
            style={{minWidth:`100px`, maxWidth:`200px`}} 
            key={`${field}${i}`}
            value={data[`${field}${i}`] ? data[`${field}${i}`] : ''}
            type="text" 
            placeholder={`${field}${i}`.toUpperCase()} 
            onChange={(e)=>{
                //validate value
                if(isValidDecimal(e.target.value) ){
                  formEdit[`${field}${i}`] = e.target.value;
                  data[`${field}${i}`] = e.target.value;
                  errors[`${field}${i}`] = null;
                  if(field.includes('height_bare') || field.includes('height_lam')){
                    this.computeFlatnessGrade(this.state.formEdit);
                  }
                  this.setState({ formEdit, errors });
                }else{
                  errors[`${field}${i}`] = "only decimal";
                  this.setState({errors});
                }
              }}
              isInvalid={!!errors[`${field}${i}`]}
          />
          <Form.Control.Feedback type="invalid">
            {this.state.errors[`${field}${i}`]}
          </Form.Control.Feedback>
        </Col>
      );
    }

    return(
      <Fragment key={field}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          {listItems}
        </Row>
      </Fragment>
    )
  }

  boolFormEdit(field, label){
    let {data} = this.state;
    return(
      <Fragment key={`${field}boolean`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Select 
              value={data[field] ? data[field] : "null"} 
              onChange={(e)=>{
                let {formEdit} = this.state;
                formEdit[field] =  e.target.value;
                data[field] = e.target.value; 
                this.setState({ formEdit, data }); 
              }}  
            >
              <option value="null">{Icons.empty}</option>
              <option value="true">{Icons.ok}</option>
              < option value="false">{Icons.wrong}</option>
            </Form.Select>
          </Col>
        </Row>  
      </Fragment>
    )
  }
  
  computeFlatnessGrade(form){
    let flatness_grade_suggestion_cuw = null;
    let flatness_grade_suggestion_ti = null;

    let height_bare = getHeight(form, 'bare');
    let height_lam = getHeight(form, 'lam');

    if(height_lam.length===0 && height_bare.length>0){
      flatness_grade_suggestion_cuw = getFlatnessGrade(height_bare, 'CuW')
      flatness_grade_suggestion_ti = getFlatnessGrade(height_bare, 'Ti')
      this.setState({flatness_grade_suggestion_cuw, flatness_grade_suggestion_ti});      
    }

    if(height_lam.length>0){
      flatness_grade_suggestion_cuw = getFlatnessGrade(height_lam, 'CuW');
      flatness_grade_suggestion_ti = getFlatnessGrade(height_lam, 'Ti');
      this.setState({flatness_grade_suggestion_cuw, flatness_grade_suggestion_ti});      
    }

    if(height_lam.length===0 && height_bare.length===0){
      this.setState({flatness_grade_suggestion_cuw:null, flatness_grade_suggestion_ti:null});      
    }
  }

  render(){
    if (this.state.loading) return <Spinner animation="border" variant="info" />

    return (
      <Fragment>
        <Modal
          show={this.state.isOpen} 
          onHide={() => {
            // this.state.isOpen = false;
            this.setState({ isOpen:false });  
          } }
          animation={false}
          size='xl'
          aria-labelledby="contained-modal-title-vcenter"
          scrollable
          centered
        >
          <Modal.Header>
            <Modal.Title>
              Edit baseplate
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ minWidth:'2000px'}}>
              {this.textFormEdit('bar_code','BAR CODE')}
              <br />
              {this.integerFormEdit('workshop_serial', 'WORKSHOP SERIAL')}
              <br />
              {this.decimalFormEdit('weight_bare', 'WEIGHT BARE')}
              <br />
              {this.decimalFormEdit('weight_lam', 'WEIGHT LAM')}
              <br />
              {this.decimalFormEdit('thickness', 'THICKNESS')}
              <br />
              {this.arrayFormEdit('height_bare',10,'HEIGHT BARE')}
              <br />
              {this.arrayFormEdit('height_lam',10,'HEIGHT LAM')}
              <br />
              <Row>
                <Form.Label column sm={1}>
                  FLATNESS GRADE
                </Form.Label>
                <Col sm={2}>
                  <Form.Select value={this.state.data['flatness_grade'] ? this.state.data['flatness_grade'] : 'null'} onChange={(e)=>{this.changeFormEdit(e,'flatness_grade')}}>
                    <option value="null">None</option>
                    <option value="green">green</option>
                    <option value="orange">orange</option>
                    <option value="red">red</option>
                  </Form.Select>
                </Col>
                <Col>
                  {(this.state.flatness_grade_suggestion_cuw!==null ||this.state.flatness_grade_suggestion_ti!==null) &&
                    <Fragment>
                      Suggested           
                      CuW <span style={{backgroundColor:this.state.flatness_grade_suggestion_cuw==='green' ? 'lightgreen' : this.state.flatness_grade_suggestion_cuw==='red' ? '#ff0000b3' : this.state.flatness_grade_suggestion_cuw }}>{this.state.flatness_grade_suggestion_cuw}</span>
                      Ti <span style={{backgroundColor:this.state.flatness_grade_suggestion_ti==='green' ? 'lightgreen' : this.state.flatness_grade_suggestion_ti==='red' ? '#ff0000b3' : this.state.flatness_grade_suggestion_ti }}>{this.state.flatness_grade_suggestion_ti}</span>
                    </Fragment>
                  }

                </Col>
              </Row>  
              <br />
              {this.arrayFormEdit('width_edge',3,'WIDTH EDGE')}  
              <br />
              {this.arrayFormEdit('width_corner',3,'WIDTH CORNER')}    
              <br />
              {this.arrayFormEdit('radius',6,'RADIUS')}      
              <br />
              {this.decimalFormEdit('min_hole_dist', 'MIN DISTANCE FROM HOLE TO SLOT','')}
              <br />
              {this.decimalFormEdit('max_hole_dist', 'MAX DISTANCE FROM HOLE TO SLOT','')}
              <br />
              {this.boolFormEdit('diameter_hole_passed','DIAMETER HOLE PASSED')}
              <br />
              {this.decimalFormEdit('length_slot', 'LENGTH OF SLOT','')}
              <br />
              {this.boolFormEdit('width_slot_passed','WIDTH SLOT PASSED')}
              <br />
              {this.boolFormEdit('notch_size_passed','NOTCH SIZE PASSED')}
              <br />
              {this.arrayFormEdit('tab_width',3,'TAB WIDTH')}  
              <br />
              {this.arrayFormEdit('tab_height',3,'TAB HEIGHT')} 
              <br />
              {this.boolFormEdit('kapton_align_passed','KAPTON ALIGN PASSED')}
              <br />
              <Row>
                <Form.Label column sm={1}>
                  TOLERANCE GRADE
                </Form.Label>
                <Col sm={2}>
                  <Form.Select value={this.state.data['tolerance_grade'] ? this.state.data['tolerance_grade'] : 'null'} onChange={(e)=>{this.changeFormEdit(e,'tolerance_grade')}}>
                    <option value="null">None</option>
                    <option value="green">green</option>
                    <option value="orange">orange</option>
                    <option value="red">red</option>
                  </Form.Select>
                </Col>
              </Row> 
              <br />
              <Row>
                <Form.Label column sm={1}>
                  COMMENT
                </Form.Label>
                <Col sm={3}>
                  <Form.Control 
                    as="textarea" 
                    rows={3}
                    onChange={(e)=>{this.changeFormEdit(e,'comment')}}
                    />
                </Col>
              </Row>
            </Form>

          </Modal.Body>
          <Modal.Footer>
            <GradingTooltip position="top" />
            
            <Button variant="secondary" onClick={() => {
            this.setState({ isOpen:false });  
            window.location.reload();      
          }}>
              Close
            </Button>
            <Button 
              variant="primary" 
              onClick={async () => {
                let {data} = this.state;
                let height_bare = [];
                let height_lam = [];
                let flatness_bare = null;
                let flatness_lam = null;
                let dist_hole_slot = null;
                Object.keys(data).forEach((item) => {
                  if(item.includes('height_bare') && data[item]!==null && data[item]!=='') height_bare.push(data[item]) 
                  if(item.includes('height_lam') && data[item]!==null && data[item]!=='') height_lam.push(data[item]) 
                })
                //calculate DIST_HOLE_SLOT if min max exist
                if(data['min_hole_dist']!==null && data['max_hole_dist']!==null && data['min_hole_dist']!=='' && data['max_hole_dist']!==''){
                  dist_hole_slot = (parseFloat(data['min_hole_dist']) + parseFloat(data['max_hole_dist'])) / 2;
                }

                //calculate FLATNESS_BARE if height_bare exist
                if(height_bare.length>0){
                  flatness_bare = (Math.max(...height_bare)-Math.min(...height_bare)).toFixed(3);
                }

                //calculate FLATNESS_LAM if height_lam exist
                if(height_lam.length>0){
                  flatness_lam = (Math.max(...height_lam)-Math.min(...height_lam)).toFixed(3);
                }

                await updateBP(this.state.id, this.state.formEdit, this.state.username, flatness_bare, flatness_lam, dist_hole_slot);   
                this.setState({isOpen: false});  
                window.location.reload();      
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default BaseplateEdit;