import React, { Component, Fragment } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import { addBP } from '../services/baseplateService';
import Icons from './common/Icons';
import { getHeight, isValidAlphanumeric, isValidDecimal, getFlatnessGrade, isPositiveInteger } from '../services/commonService';


class BaseplateAdd extends Component {
  constructor(props){
    super(props);
    this.state = {
      isOpen: props.isOpen,
      user: props.user,
      flatness_grade_suggestion_cuw: null,
      flatness_grade_suggestion_ti: null,
      errors:{},
      formAdd: {
        bar_code : null,
        workshop_serial: null,
        weight_bare: null,
        weight_lam: null,
        thickness: null,
        flatness_grade: null,
        diameter_hole_passed: null,
        length_of_slot: null,
        width_lot_passed: null,
        notch_size_passed: null,
        kapton_align_passed: null,
        test_date: null,
        tolerance_grade: null,
        comment: null,
        height_bare0 : null,
        height_bare1 : null,
        height_bare2 : null,
        height_bare3 : null,
        height_bare4 : null,
        height_bare5 : null,
        height_bare6 : null,
        height_bare7 : null,
        height_bare8 : null,
        height_bare9 : null,
        height_lam0 : null,
        height_lam1 : null,
        height_lam2 : null,
        height_lam3 : null,
        height_lam4 : null,
        height_lam5 : null,
        height_lam6 : null,
        height_lam7 : null,
        height_lam8 : null,
        height_lam9 : null,
        radius0 : null,
        radius1 : null,
        radius2 : null,
        radius3 : null,
        radius4 : null,
        radius5 : null,
        width_edge0 : null,
        width_edge1 : null,
        width_edge2 : null,
        width_corner0 : null,
        width_corner1 : null,
        width_corner2 : null,
        tab_width0 : null,
        tab_width1 : null,
        tab_width2 : null,
        tab_height0 : null,
        tab_height1 : null,
        tab_height2 : null,
        min_hole_dist: null,
        max_hole_dist: null,
      }
    }
  }

  async componentDidMount(){
    //call kind of parts
  }

  changeFormAdd(e, field){
    let {formAdd} = this.state;
    formAdd[field] =  e.target.value;
    this.setState({formAdd});
  }


  arrayFormAdd(field, size, label){
    let {formAdd, errors} = this.state;
    const listItems = [];
    for (let i = 0; i < size; i++) {
      listItems.push(
        <Col key={`col_${field}${i}`} sm={1}>
          <Form.Control 
            style={{minWidth:`100px`, maxWidth:`200px`}} 
            key={`${field}${i}`}
            type="text" 
            placeholder={`${field}${i}`.toUpperCase()} 
            onChange={(e)=>{
                //validate value
                if(isValidDecimal(e.target.value) ){
                  formAdd[`${field}${i}`] = e.target.value;
                  errors[`${field}${i}`] = null;
                  if(field.includes('height_bare') || field.includes('height_lam')){
                    this.computeFlatnessGrade();
                  }
                  this.setState({ formAdd, errors });
                }else{
                  errors[`${field}${i}`] = "only decimal";
                  this.setState({errors});
                }
              }}
              isInvalid={!!errors[`${field}${i}`]}
          />
          <Form.Control.Feedback type="invalid">
            {this.state.errors[`${field}${i}`]}
          </Form.Control.Feedback>
        </Col>
      );
    }

    return(
      <Fragment key={`add_${field}`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          {listItems}
        </Row>
      </Fragment>
    )
  }

  boolFormAdd(field, label){
    return(
      <Fragment key={`${field}boolean`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Select onChange={(e)=>{this.changeFormAdd(e,field)}}>
              <option value="null">{Icons.empty}</option>
              <option value="true">{Icons.ok}</option>
              < option value="false">{Icons.wrong}</option>
            </Form.Select>
          </Col>
        </Row>  
      </Fragment>
    )
  }

  fieldFormAddDecimal(field, label, placeholder){
    let {formAdd, errors} = this.state;
    return(
      <Fragment key={`${field}field`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Control 
              type="text" 
              placeholder={placeholder} 
              onChange={(e)=>{
                //validate value
                if(isValidDecimal(e.target.value) ){
                  formAdd[field] = e.target.value;
                  errors[field] = null;
                  this.setState({ formAdd, errors });
                }else{
                  errors[field] = "only decimal";
                  this.setState({errors});
                }
              }}
              isInvalid={!!errors[field]}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errors[field]}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Fragment>
    )
  }


  fieldFormAddInteger(field, label, placeholder){
    let {formAdd, errors} = this.state;
    return(
      <Fragment key={`${field}field`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Control 
              type="text" 
              placeholder={placeholder} 
              onChange={(e)=>{
                //validate value
                if(isPositiveInteger(e.target.value) ){
                  formAdd[field] = e.target.value;
                  errors[field] = null;
                  this.setState({ formAdd, errors });
                }else{
                  errors[field] = "only integer > 0";
                  this.setState({errors});
                }
              }}
              isInvalid={!!errors[field]}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errors[field]}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Fragment>
    )
  }

  fieldFormAddAlphanumerical(field, label, placeholder){
    let {formAdd, errors} = this.state;
    return(
      <Fragment key={`${field}field`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Control 
              type="text" 
              placeholder={placeholder} 
              onChange={(e)=>{
                //validate value
                if(isValidAlphanumeric(e.target.value) ){
                  formAdd[field] = e.target.value;
                  errors[field] = null;
                  this.setState({ formAdd, errors });
                }else{
                  errors[field] = "only alphanumerical characters";
                  this.setState({errors});
                }
              }}
              isInvalid={!!errors[field]}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errors[field]}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Fragment>
    )
  }

  computeFlatnessGrade(){
    let flatness_grade_suggestion_cuw = null;
    let flatness_grade_suggestion_ti = null;

    let height_bare = getHeight(this.state.formAdd, 'bare');
    let height_lam = getHeight(this.state.formAdd, 'lam');

    if(height_lam.length===0 && height_bare.length>0){
      flatness_grade_suggestion_cuw = getFlatnessGrade(height_bare, 'CuW')
      flatness_grade_suggestion_ti = getFlatnessGrade(height_bare, 'Ti')
      this.setState({flatness_grade_suggestion_cuw, flatness_grade_suggestion_ti});      
    }

    if(height_lam.length>0){
      flatness_grade_suggestion_cuw = getFlatnessGrade(height_lam, 'CuW');
      flatness_grade_suggestion_ti = getFlatnessGrade(height_lam, 'Ti');
      this.setState({flatness_grade_suggestion_cuw, flatness_grade_suggestion_ti});      
    }

    if(height_lam.length===0 && height_bare.length===0){
      this.setState({flatness_grade_suggestion_cuw:null, flatness_grade_suggestion_ti:null});      
    }
  }

  render(){

    return(
      <Fragment>
        <Modal 
          show={this.state.isOpen} 
          onHide={() => {
            this.setState({isOpen:false});  
          } }
          animation={false}
          size='xl'
          aria-labelledby="contained-modal-title-vcenter"
          scrollable
          centered
        >
          <Modal.Header>
            <Modal.Title>Add new baseplate</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ minWidth:'2000px'}}>
              {this.fieldFormAddAlphanumerical('bar_code','BAR CODE','e.g. 320BAFLWKA00173')}
              <br />
              {this.fieldFormAddInteger('workshop_serial', 'WORKSHOP SERIAL','')}
              <br />
              {this.fieldFormAddDecimal('weight_bare', 'WEIGHT BARE','')}
              <br />
              {this.fieldFormAddDecimal('weight_lam', 'WEIGHT LAM','')}
              <br />
              {this.fieldFormAddDecimal('thickness', 'THICKNESS','')}
              <br />
              {this.arrayFormAdd('height_bare',10,'HEIGHT BARE')}
              <br />
              {this.arrayFormAdd('height_lam',10,'HEIGHT LAM')}
              <br />
              <Row>
                <Form.Label column sm={1}>
                  FLATNESS GRADE
                </Form.Label>
                <Col sm={2}>
                  <Form.Select onChange={(e)=>{this.changeFormAdd(e,'flatness_grade')}}>
                    <option value="null">None</option>
                    <option value="green">green</option>
                    <option value="orange">orange</option>
                    <option value="red">red</option>
                  </Form.Select>
                </Col>
                <Col>
                  {(this.state.flatness_grade_suggestion_cuw!==null ||this.state.flatness_grade_suggestion_ti!==null) &&
                    <Fragment>
                      Suggested           
                      CuW <span style={{backgroundColor:this.state.flatness_grade_suggestion_cuw==='green' ? 'lightgreen' : this.state.flatness_grade_suggestion_cuw==='red' ? '#ff0000b3' : this.state.flatness_grade_suggestion_cuw }}>{this.state.flatness_grade_suggestion_cuw}</span>
                      Ti <span style={{backgroundColor:this.state.flatness_grade_suggestion_ti==='green' ? 'lightgreen' : this.state.flatness_grade_suggestion_ti==='red' ? '#ff0000b3' : this.state.flatness_grade_suggestion_ti }}>{this.state.flatness_grade_suggestion_ti}</span>

                    </Fragment>
                  }

                </Col>
              </Row>  
              <br />
              {this.arrayFormAdd('width_edge',3,'WIDTH EDGE')}  
              <br />
              {this.arrayFormAdd('width_corner',3,'WIDTH CORNER')}    
              <br />
              {this.arrayFormAdd('radius',6,'RADIUS')}      
              <br />
              {this.fieldFormAddDecimal('min_hole_dist', 'MIN DIST HOLE TO SLOT','')}
              <br />
              {this.fieldFormAddDecimal('max_hole_dist', 'MAX DIST HOLE TO SLOT','')}
              <br />
              {this.boolFormAdd('diameter_hole_passed','DIAMETER HOLE PASSED')}
              <br />
              {this.fieldFormAddDecimal('length_slot', 'LENGTH OF SLOT','')}
              <br />
              {this.boolFormAdd('width_slot_passed','WIDTH SLOT PASSED')}
              <br />
              {this.boolFormAdd('notch_size_passed','NOTCH SIZE PASSED')}
              <br />
              {this.arrayFormAdd('tab_width',3,'TAB WIDTH')}  
              <br />
              {this.arrayFormAdd('tab_height',3,'TAB HEIGHT')} 
              <br />
              {this.boolFormAdd('kapton_align_passed','KAPTON ALIGN PASSED')}
              <br />
              <Row>
                <Form.Label column sm={1}>
                  TOLERANCE GRADE
                </Form.Label>
                <Col sm={2}>
                  <Form.Select onChange={(e)=>{this.changeFormAdd(e,'tolerance_grade')}}>
                    <option value="null">None</option>
                    <option value="green">green</option>
                    <option value="orange">orange</option>
                    <option value="red">red</option>
                  </Form.Select>
                </Col>
              </Row> 
              <br />
              <Row>
                <Form.Label column sm={1}>
                  COMMENT
                </Form.Label>
                <Col sm={3}>
                  <Form.Control 
                    as="textarea" 
                    rows={3}
                    onChange={(e)=>{this.changeFormAdd(e,'comment')}}
                    />
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {
            this.setState({isOpen:false});  
            window.location.reload();      

          }}>
              Close
            </Button>
            <Button 
              variant="primary" 
              onClick={async () => {
                let new_item = await addBP(this.state.formAdd, this.state.user.username);
                // NEED TO SETUP A CALLBACK LAGER
                // let new_data = this.state.data;
                // new_data.push(new_item);     
                this.setState({isOpen: false});  
                window.location.reload();      
              }}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );

  }
}

export default BaseplateAdd;